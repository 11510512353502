/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Action,
  getModule,
  Mutation
} from "vuex-module-decorators";
import store from "@/store";
import { EditCargoConfigurationRequest } from "@/data/payload/api/CargoConfigurationApiRequest";
import { CargoConfigurationPresenter } from "../presenters/CargoConfigurationPresenter";
import { CargoConfigListRequest } from "@/data/payload/api/CargoConfigurationApiRequest";
import {
  CargoConfigEntities,
  CargoConfiguration
} from "@/domain/entities/CargoConfiguration";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { AddCargoConfigurationRequest } from "@/data/payload/api/CargoConfigurationApiRequest";
import { CargoRecomendationsListRequest } from "@/data/payload/api/CargoApiRequest";
import { CityData } from "@/domain/entities/Location";
import { OptionsClass } from "@/domain/entities/MainApp";
import { Pagination } from "@/domain/entities/Pagination";

@Module({ namespaced: true, dynamic: true, store, name: "cargo-configuration" })
class CargoConfigurationStore extends VuexModule {
  public filterData = {
    origin: "" as CityData | string,
    destination: "" as CityData | string,
    status: "",
    clientName: "",
    clientId: 0
  };
  public firstRequest = true;
  public isLoading = true;
  public errorCause = "";
  public transportType = new OptionsClass();
  public cargoConfigList: any = new CargoConfigEntities(
    new Pagination(1, 20),
    []
  );
  @Action
  public GetListCargoConfig(params: CargoConfigListRequest) {
    const presenter = container.resolve(CargoConfigurationPresenter);
    return presenter.getList(params);
  }

  @Action
  getDetailCargoConfiguration(id: number): Promise<CargoConfiguration> {
    const presenter = container.resolve(CargoConfigurationPresenter);
    return presenter.getDetail(id);
  }

  @Action
  public onEdit(params: EditCargoConfigurationRequest) {
    const presenter = container.resolve(CargoConfigurationPresenter);
    return presenter.edit(params);
  }

  @Action
  public onDelete(id: number) {
    const presenter = container.resolve(CargoConfigurationPresenter);
    return presenter.delete(id);
  }

  @Action
  async addCargoConfiguration(payload: AddCargoConfigurationRequest) {
    MainAppController.showLoading();
    const presenter = container.resolve(CargoConfigurationPresenter);

    return presenter.addCargoConfiguration(payload);
  }

  @Action
  getListCargoRecomendation(
    params: CargoRecomendationsListRequest
  ): Promise<CargoConfiguration[]> {
    const presenter = container.resolve(CargoConfigurationPresenter);
    return presenter.getListCargoRecomendation(params);
  }

  @Action
  public async resetFilter() {
    this.cargoConfigList.pagination.page = 1;
    this.cargoConfigList.pagination.limit = 20;
    this.transportType.value = "";
    this.setFilter({
      origin: "" as CityData | string,
      destination: "" as CityData | string,
      status: "",
      clientName: "",
      clientId: 0
    });
  }

  @Mutation
  public setIsLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  public setCargoConfigList(data: CargoConfigEntities) {
    this.cargoConfigList = data;
  }

  @Mutation
  public setFilter(value: any) {
    this.filterData = value;
  }

  @Mutation
  public setTransportType(value: any) {
    this.transportType = value;
  }
}

export const CargoConfigurationController = getModule(CargoConfigurationStore);
