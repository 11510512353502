/* eslint-disable @typescript-eslint/camelcase */
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { CargoConfigurationRequestInterface } from "@/data/payload/contracts/CargoConfigurationRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import {
  CargoConfigurationFlightDetail,
  CargoConfigurationFlightTransitDetail,
  CutOffTimeConfigCargo,
  RequestFormCargoConfiguration
} from "@/domain/entities/CargoConfiguration";

const convertPayloadFormSnakeCase = (
  payload: RequestFormCargoConfiguration
) => {
  return ConvertObjectCamelToSnakeCase(
    new RequestFormCargoConfiguration({
      ...payload,
      cargoConfigurationStatus: payload.cargoConfigurationStatus
        ? "active"
        : "inactive",
      // convert key to snake_case object cargo_fligt in payload
      cargoFlight: payload.cargoFlight.length
        ? payload.cargoFlight.map((flight: CargoConfigurationFlightDetail) =>
            ConvertObjectCamelToSnakeCase(
              new CargoConfigurationFlightDetail({
                ...flight,
                // convert key to snake_case object cargo_fligt_transit in flight object
                cargoFlightTransit: flight.cargoFlightTransit.length
                  ? flight.cargoFlightTransit.map(
                      (transit: CargoConfigurationFlightTransitDetail) =>
                        ConvertObjectCamelToSnakeCase(transit)
                    )
                  : []
              })
            )
          )
        : [],
      cargoConfigurationCutOffTime: payload.cargoConfigurationCutOffTime.length
        ? payload.cargoConfigurationCutOffTime.map(
            (item: CutOffTimeConfigCargo) =>
              ConvertObjectCamelToSnakeCase(new CutOffTimeConfigCargo(item))
          )
        : []
    })
  );
};

export class CargoFlightTransitRequest {
  cargoFlightNumber = "";
  cargoFlightDepartureTime = "";
  cargoFlightArrivalTime = "";
  cargoFlightDepartureTimezone = "";
  cargoFlightArrivalTimezone = "";

  constructor(fields?: Partial<CargoFlightTransitRequest>) {
    Object.assign(this, fields);
  }
}

export class CargoFlightRequest {
  cargoFlightNumber = "";
  cargoFlightStartTime = "";
  cargoFlightEndTime = "";
  cargoFlightDepartureTime = "";
  cargoFlightArrivalTime = "";
  cargoFlightDepartureTimezone = "";
  cargoFlightArrivalTimezone = "";
  cargoFlightTransit: CargoFlightTransitRequest[] = [
    new CargoFlightTransitRequest()
  ];

  constructor(fields?: Partial<CargoFlightRequest>) {
    Object.assign(this, fields);
  }
}

export class AddCargoConfigurationRequest
  implements CargoConfigurationRequestInterface {
  payload: RequestFormCargoConfiguration = new RequestFormCargoConfiguration();
  constructor(fields?: Partial<EditCargoConfigurationRequest>) {
    Object.assign(this, fields);
  }

  public toJSON() {
    return JSON.stringify(convertPayloadFormSnakeCase(this.payload));
  }
}

export class EditCargoConfigurationRequest
  implements CargoConfigurationRequestInterface {
  payload: RequestFormCargoConfiguration = new RequestFormCargoConfiguration();
  constructor(fields?: Partial<EditCargoConfigurationRequest>) {
    Object.assign(this, fields);
  }

  public toJSON() {
    return JSON.stringify(convertPayloadFormSnakeCase(this.payload));
  }
}

export class CargoConfigListRequest {
  page = 1;
  limit = 20;
  originCode = "";
  destinationCode = "";
  status = "";
  transportType = "";
  clientParentId = 0;

  constructor(fields?: Partial<CargoConfigListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
