import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-3/4"
}
const _hoisted_2 = { class: "grid gap-6 grid-cols-3 space-y-3" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "py-2 overflow-auto"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 1,
  class: "mt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_Title = _resolveComponent("Title")!
  const _component_ScheduleCard = _resolveComponent("ScheduleCard")!
  const _component_CargoTypeCard = _resolveComponent("CargoTypeCard")!
  const _component_list_cargo_type = _resolveComponent("list-cargo-type")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_PlaneSchedule = _resolveComponent("PlaneSchedule")!
  const _component_ModalPlaneAuto = _resolveComponent("ModalPlaneAuto")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (!_ctx.showPlaneSchedule)
      ? _withDirectives((_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_DataWrapper, {
              label: "Kota Tujuan",
              value: _ctx.destinationCity?.name
            }, null, 8, ["value"]),
            _createVNode(_component_DataWrapper, {
              label: "Komoditi",
              value: _ctx.form.cargoCommodityName,
              class: "col-span-2"
            }, null, 8, ["value"]),
            _createVNode(_component_DataWrapper, {
              label: "Total STT",
              value: _ctx.totalStt
            }, null, 8, ["value"]),
            _createVNode(_component_DataWrapper, {
              label: "Total Berat",
              value: _ctx.form.actualTotalCargoGrossWeight
            }, null, 8, ["value"]),
            _createVNode(_component_DataWrapper, {
              label: "Total Volume",
              value: _ctx.form.actualTotalCargoVolumeWeight
            }, null, 8, ["value"])
          ]),
          (
        !_ctx.apiStateData.loading &&
          (_ctx.cargoRecomendationsData.length || _ctx.isPlaneAutoRecommendation) &&
          !_ctx.isLoading
      )
            ? (_openBlock(), _createBlock(_component_Tabs, {
                key: 0,
                modelValue: _ctx.activeMenu,
                onChange: _ctx.onChangeHistoryTabs,
                data: _ctx.tabs,
                rounded: false,
                titleSize: 16
              }, {
                default: _withCtx(() => [
                  (_ctx.activeMenu === 'recomendation')
                    ? (_openBlock(), _createBlock("div", _hoisted_3, [
                        _createVNode(_component_Title, {
                          label: _ctx.formatDateWithoutTime(_ctx.form.estDepartureDate),
                          class: "mb-3"
                        }, null, 8, ["label"]),
                        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.cargoRecomendationsData, (item, index) => {
                          return (_openBlock(), _createBlock("div", {
                            key: index,
                            class: "py-2 overflow-auto"
                          }, [
                            (item.transportationType.value === 'plane')
                              ? (_openBlock(), _createBlock(_component_ScheduleCard, {
                                  key: 0,
                                  data: item.flightData,
                                  isWatchProgress: true,
                                  onSelectPlane: () => _ctx.showPopupMaxKoliPlane(item)
                                }, null, 8, ["data", "onSelectPlane"]))
                              : (_openBlock(), _createBlock(_component_CargoTypeCard, {
                                  key: 1,
                                  icon: _ctx.cargoType(item.transportationType.value).icon,
                                  type: _ctx.cargoType(item.transportationType.value).label,
                                  origin: _ctx.originCity.name,
                                  destination: _ctx.destinationCity?.name,
                                  isRecommended: "",
                                  onClick: () => _ctx.onSelectCargoRecomendation(item)
                                }, null, 8, ["icon", "type", "origin", "destination", "onClick"]))
                          ]))
                        }), 128)),
                        (_ctx.isPlaneAutoRecommendation)
                          ? (_openBlock(), _createBlock("div", _hoisted_4, [
                              _createVNode(_component_CargoTypeCard, {
                                isManual: false,
                                icon: _ctx.cargoType('plane').icon,
                                type: _ctx.cargoType('plane').label,
                                origin: _ctx.originCity.name,
                                destination: _ctx.destinationCity?.name,
                                isRecommended: "",
                                onClick: _cache[1] || (_cache[1] = () => _ctx.showPopupMaxKoliPlaneAuto('plane', 'auto'))
                              }, null, 8, ["icon", "type", "origin", "destination"])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : (_openBlock(), _createBlock("div", _hoisted_5, [
                        _createVNode(_component_list_cargo_type)
                      ]))
                ]),
                _: 1
              }, 8, ["modelValue", "onChange", "data"]))
            : (_openBlock(), _createBlock("div", _hoisted_6, [
                _createVNode(_component_Title, {
                  label: "Pilihan Kargo",
                  fontSize: 20,
                  class: "mb-3"
                }),
                _createVNode(_component_list_cargo_type)
              ]))
        ], 512)), [
          [_vShow, false]
        ])
      : (_ctx.showPlaneSchedule)
        ? (_openBlock(), _createBlock(_component_PlaneSchedule, {
            key: 1,
            onChangeAirport: _ctx.onResetChangeTransitAirport,
            width: "w-11/12"
          }, null, 8, ["onChangeAirport"]))
        : _createCommentVNode("", true),
    _createVNode("div", null, [
      _createVNode(_component_ModalPlaneAuto, {
        onClosePopup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchAirportOrigin(this.originCity.code || '', true)))
      })
    ])
  ], 64))
}